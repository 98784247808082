<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pb-3">
        <b-row class="pt-3">
          <b-col>
            <FilteringPanelWrapper
              :model="model"
              :filters="filters"
              @get-data="onFilteringPanelGetData"
            ></FilteringPanelWrapper>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <TableWrapper
              :ref="model.name"
              :model="model"
              :parent-id="id"
              actions-mode="inline"
              :columns="tableColumns"
              :read-only="false"
              :filter-by-column="false"
              @inserted="onTableInsert"
              @updated="onTableChange"
            >
            </TableWrapper>
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 10em;"> </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import models from "@/models";
import FilteringPanelWrapper from "@/components/DataView/FilteringPanelWrapper";
import TableWrapper from "@/components/DataView/TableWrapper";

export default {
  props: {
    id: {
      type: [String, Number],
      default: ""
    },
    mode: {
      type: Number,
      default: 0
    },
    active: {
      type: [Number, Boolean],
      default: 0
    }
  },
  name: "ImportantDates",
  components: { FilteringPanelWrapper, TableWrapper },
  data: function() {
    return {
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      model: models.trackTime.importantDates,
      filters: ["Date", "Category"],
      tableColumns: ["ID", "Date", "Category", "Description"],
      data: {}
    };
  },
  created() {},
  mounted() {},
  methods: {
    onTableInsert() {},
    onTableChange() {},
    async onFilteringPanelGetData(payload) {
      await this.$refs[this.model.name].getData(payload);

      this.$refs[this.model.name].setOrder("Date");
    }
  },
  watch: {}
};
</script>

<style scoped>
/*
::v-deep .flex-row {
  width: 100%;
}*/
::v-deep .w-short {
  width: 30em !important;
}

::v-deep .VueTables__table {
  overflow: visible !important;
}
::v-deep .table-responsive {
  overflow: visible !important;
}
</style>
